<template>
    <div
        :class="['drop-area', { '-is-dragover': state.dragOver }]"
        @dragenter.prevent="state.dragOver = true"
        @dragover.prevent="state.dragOver = true"
        @dragleave.prevent="state.dragOver = false"
        @dragend.prevent="state.dragOver = false"
        @drag.prevent
        @drop.prevent="handleDrop"
    >
        <div
            v-for="(image, i) in images"
            :key="i"
            class="drop-area__image-wrap"
        >
            <v-image :image="image" />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import api from '../../../plugins/api';
import to from '../../../plugins/to';
import VImage from './Image.vue';
import echo from '../../../plugins/echo';

export default {
    components: { VImage },
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: Array,
            default: () => [],
        },
        albumId: {
            type: [Number, Boolean],
            default: false,
        },
    },
    data: () => ({
        state: {
            dragOver: false,
            uploading: false,
        },
        images: [],
        uploaded: 0,
    }),
    watch: {
        albumId() {
            // Subscribe to album thumbnail channel.  This channel will load in the thumbnails
            // once they are transformed since we're transforming them in a queued task.
            echo.private(`album.${this.albumId}.thumbnails`)
                .listen('AlbumThumbnailGenerated', (image) => {
                    this.images = this.images.filter((img) => img.filename !== image.filename);
                });
        },
        uploaded() {
            this.setUploading(this.uploaded !== this.images.length);
        },
    },
    computed: {
        ...mapState('imageUploader', [
            'uploading',
        ]),
    },
    methods: {
        ...mapActions('imageUploader', [
            'setUploading',
        ]),
        handleDrop(e) {
            const { files } = e.dataTransfer;
            const images = [...files];

            images.forEach(async (file) => {
                const formData = new FormData();
                formData.append('image', file);

                const imageObject = {
                    file,
                    progress: 0,
                };

                this.images = [
                    ...this.images,
                    imageObject,
                ];

                const index = this.images.indexOf(imageObject);

                const [err, res] = await to(api.post('/temporary-images', formData, {
                    'content-type': 'multipart/form-data',
                    onUploadProgress: (progress) => {
                        this.images[index].progress = Math.round((progress.loaded * 100) / progress.total);
                    },
                }));

                if (!err) {
                    const { data: { data: image } } = res;

                    this.uploaded += 1;

                    imageObject.id = image.id;
                    imageObject.path = image.path;
                    imageObject.originalFilename = image.originalFilename;
                    imageObject.filename = image.filename;

                    this.$emit('update:modelValue', this.images);
                } else {
                    console.error(err);
                }
            });

            this.state.dragOver = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.drop-area {
    border: 1px dashed $lightGray;
    display: grid;
    grid-gap: 2rem;
    grid-template-rows: 1fr;
    margin-bottom: 2rem;
    min-height: 20rem;
    padding: 2rem;
    width: 100%;

    &__image {
        height: auto;
        vertical-align: bottom;
        width: 100%;
    }

    &__progress {
        background-color: $lightestGray;
        height: 5px;
        width: 100%;
    }

    &__progress-bar {
        background-color: $primary;
        height: 5px;
        transition: width .2s ease-in-out;
        width: 0%;
    }

    &.-is-dragover {
        border-color: $primary;
    }
}

@include tablet {
    .drop-area {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;
    }
}

@include desktop {
    .drop-area {
        grid-template-columns: repeat(3, 1fr);
    }
}

@include desktop2 {
    .drop-area {
        grid-template-columns: repeat(4, 1fr);
    }
}

</style>
