<template>
    <div class="image">
        <img
            :src="src"
            class="image__img"
        >
        <div class="image__progress">
            <div
                class="image__progress-bar"
                :style="{ width: `${image.progress}%` }"
            />
        </div>
    </div>
</template>

<script>
import { _doc } from '@weepower/core/scripts/core/variables';

export default {
    name: 'Image',
    props: {
        image: {
            type: Object,
            required: true,
        },
    },
    async mounted() {
        const reader = new FileReader();
        reader.readAsDataURL(this.image.file);

        reader.onloadend = await this.onReaderLoad;
    },
    methods: {
        async onReaderLoad(event) {
            const { result } = event.target;
            const { target: image } = await this.createImage(result);
            const canvas = _doc.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const copyCanvas = _doc.createElement('canvas');
            const copyCtx = copyCanvas.getContext('2d');

            let ratio = 1;

            if (image.width > 600) {
                ratio = 600 / image.width;
            } else if (image.height > 200) {
                ratio = 200 / image.height;
            }

            copyCanvas.width = image.width;
            copyCanvas.height = image.height;
            copyCtx.drawImage(image, 0, 0);

            canvas.width = image.width * ratio;
            canvas.height = image.height * ratio;
            ctx.drawImage(copyCanvas, 0, 0, copyCanvas.width, copyCanvas.height, 0, 0, canvas.width, canvas.height);

            this.src = canvas.toDataURL();
        },
        async createImage(dataUrl) {
            return new Promise((resolve, reject) => {
                const image = new Image();

                image.addEventListener('load', resolve);
                image.addEventListener('error', reject);
                image.src = dataUrl;
            });
        },
    },
    data: () => ({
        src: '',
    }),
};
</script>

<style lang="scss" scoped>
.image {
    background-color: $white;
    //height: 20rem;
    display: flex;
    justify-content: center;
    border-radius: var(--border-radius);
    position: relative;

    &__progress,
    &__progress-bar {
        @include absolute(false, 0, 0, 0);

        height: .5rem;
        //border-bottom-right-radius: var(--border-radius);
        //border-bottom-left-radius: var(--border-radius);
    }

    &__progress-bar {
        background: var(--image-upload-progress-color);
    }

    &__img {
        vertical-align: bottom;
        max-height: 100%;
    }
}
</style>
